<template>
  <div class="">
    <div class="container vh-100 d-flex align-items-center">
      <div class="row d-flex align-items-end">
        <div class="col-sm-12 col-md-6 p-5">
          <h1>Connettività Tailor Made</h1>
          <p> Per connettività Tailor made si intende un profilo di banda costruito e basato sulle esigenze del cliente; per fare ciò packetloss monitora periodicamente i consumi di ogni utente e offre il servizio più adatto e su misura per l'utente finale. </p>
          <a href="mailto:info@packetloss.it" class="btn-custom px-4 py-2">Contattaci</a>
        </div>
        <div class="col d-md-flex align-items-center justify-content-center">
          <img src="../assets/images/taylor.png" class="w-100" alt="">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Personalizzazione</h5>
              <p class="card-text"> Un profilo di banda personalizzato, basato su esigenze e consumi; creato appositamente per il cliente finale.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Flessibilità</h5>
              <p class="card-text"> La flessbilità di Packet Loss permette al cliente di utilizzare tagli di banda per periodi determinati a seconda della propria esigenza. </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Garanzia</h5>
              <p class="card-text"> "Qualità e non quantità"; questo è il motto di Packet Loss, arrivando a garantire profili di banda molto alti rispetto alla concorrenza.</p>
            </div>
          </div>
        </div>
        <!--        <div class="col-7">-->
        <!--          <p>button</p>-->
        <!--          <p>button</p>-->
        <!--          <p>button</p>-->
        <!--        </div>-->
        <!--        <div class="col-5">image</div>-->
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "tailorMade"
}
</script>

<style scoped>

</style>