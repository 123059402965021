<template>
  <div class="">
    <div class="container vh-100 d-flex align-items-center">
      <div class="row d-flex align-items-end">
        <div class="col-sm-12 col-md-6 p-5">
          <h1>Connessioni di Backup</h1>
          <p> Una connesione di backup che permette al cliente di non avere fermi aziendali. 
            Grazie ad un Failover preconfigurato il cliente, nel caso di un malfunzionamento della rete primaria, è in grado di continuare a navigare senza interruzioni.
          </p>
          <a href="mail:info@packetloss.it" class="btn-custom px-4 py-2">contattaci</a>
        </div>
        <div class="col-sm-5 d-md-flex align-items-center justify-content-center">
          <img src="../assets/images/backup.png" class="w-100" alt="">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Continuità</h5>
              <p class="card-text"> Un profilo di backup sempre attivo che entra in funzione automaticamente senza esigenze di intervento tecnico.  </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Ad Utilizzo</h5>
              <p class="card-text"> Una alternativa al backup tradizionale che si attiva solo quando ne hai bisogno. Il passaggio può essere automatico o manuale. </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Ridondanza</h5>
              <p class="card-text"> Due configurazione personalizzate che permettono di utilizzare la linea di backup come Failover o come Active-Backup.

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "backup"
}
</script>

<style scoped>

</style>