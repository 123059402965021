<template>
  <div class="">
    <div class="container vh-100 d-flex align-items-center">
      <div class="row d-flex align-items-end">
        <div class="col-sm-12 col-md-6 p-5">
          <h1>Connettività intersede</h1>
          <p>Un metodo di collegamento che permette ad una azienda di comunicare con i propri servizi. 
             Specifico per aziende che hanno sedi distanziate disclocate nel territorio permettendo la creazione di una unica rete interna. </p>
          <a href="mail:info@packetloss.it" class="btn-custom px-4 py-2">Contattaci</a>
        </div>
        <div class="col d-md-flex align-items-center justify-content-center">
          <img src="../assets/images/intersede300.png" class="w-100" alt="">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Point to point</h5>
              <p class="card-text"> I ponti radio utilizzati offrono collegamenti a bassa latenza con distanze superiori a 20 km e throughput elevati fino a 10 Gbps.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Transito L2 puro</h5>
              <p class="card-text"> Una soluzione dedicata di transito L2 modulabile per interconnettere le sedi con una infrastruttura dedicata. </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card p-2 m-3 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Transito L2 MPLS</h5>
              <p class="card-text">Alternativa ad una ptp dedicata, la rete Packet Loss permette l'interconnessione di più sedi attraverso tunnel virtuali ad alte prestazioni. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "intersede"
}
</script>

<style scoped>

</style>